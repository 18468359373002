import { useNavigate } from "react-router-dom";
import {useRef, useState} from 'react'
import {useApp} from "../context/appContext";
import AlertDialog from "../components/ui/AlertDialog";
import {IoLockClosedOutline, IoEye} from "react-icons/io5";

export default function Login() {
    const {loading, setLoading, urlbase, loadSessionToken} = useApp();
    const [user, setUser ] = useState({
        user:'',
        password:''
    });
    const inputPass = useRef(null);
    const [alert, setAlert ] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            button.disabled = true;
            setLoading(true);
            let response = await fetch(urlbase+"/login_user", {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(user)
            });
            const res = await response.json();
            if (res.token) {
                let load = await loadSessionToken(res);
                if (res.user.rol == 'Vendedor') {
                    navigate("/sucursal/"+res.user.branch+"/inicio");
                }else{
                    navigate("/app");
                }
            }else{
                setAlert(res.message);
                setUser({user:'', password:''});
            }
            setLoading(false);
            button.disabled = false;
        } catch(e) {
            // statements
            console.log(e);
            setAlert("No se pudo conectar al servidor");
            button.disabled = false;
            setLoading(false);
            setUser({user:'', password:''});
        }
    }
    const handleChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleInput = (e) => {
        if(inputPass.current.type == "password"){
            inputPass.current.type = 'text';
            e.currentTarget.classList.add('blue-focus');
        }else{
            e.currentTarget.classList.remove('blue-focus');
            inputPass.current.type = 'password';
        }
    }

    return (
        <div className="landing_ani fadeIn">
            <div className="row content-central mt-3">
                <div className="col col-md-12 position-relative hg-md-200">
                    <div className="opacy_login"></div>
                    <img src="/images/controles.jpg" alt="" className="bg-image" />
                </div>
                <div className="col col-md-12">
                    <div className="cont_login py-0">
                        <div className="contene_log" id="cont_login">
                            <figure className="cont_img cont_img_l">
                                <img className="img_tit_log" src="/images/icon.png" />
                                <div className="limpio n-vert-mg">
                                    <p className="text-secondary">Donde nacen las obras maestras</p>
                                </div>
                            </figure>
                            <div className="w-100 py-1"></div>
                            <form className="form_login" action="/login" method="post">
                                <div className="cnt_inp">
                                    <img src="/images/usuario.png" className="img-inp"/>
                                    <input className="inp_login w-100" type="text" name="user" value={user.user} onChange={handleChange} placeholder="Codigo ó correo" />
                                </div>
                                <div className="cnt_inp">
                                    <IoLockClosedOutline className="icon-inp"/>
                                    <input className="inp_login w-100" ref={inputPass} type="password" name="password" value={user.password} onChange={handleChange} placeholder="Contraseña..."/>
                                    <IoEye className="show-pass" onClick={handleInput}></IoEye>
                                </div>
                                <div className="cnt_inp my-2">
                                    <div className="row w-100 justify-end">
                                        <a className="is_link">Olvide mi cuenta</a>
                                    </div>
                                </div>

                                <div className="cnt_inp t_center">
                                    <button className="btn_login waves-effect button waves-light" id="btn_login" onClick={handleLogin}>
                                        <div className="row justify-center justify-items-center w-100">
                                            {loading == false ? <span className="fs-7 fw-500">Entrar</span> : ''}
                                            {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                        </div>
                                    </button>
                                </div>
                                <div className="cnt_inp">
                                    {alert !== null ? (<div className="row my-2">
                                        <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                                    </div>) : ''}
                                </div>
                            </form>
                        </div>
                        <div className="p-3"></div>
                    </div>
                </div>
            </div>  
        </div>
    )
}
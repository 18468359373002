import {useState, useEffect} from 'react'
import {IoListSharp, IoPlaySkipBack, IoPlayCircleSharp, IoPlaySkipForward, IoClose, IoPauseCircleSharp} from "react-icons/io5";
import { AudioPlayer } from 'react-audio-play';
import { useApp } from "../../context/appContext";
import {limitText} from "../utility/utils"

export default function TrackPlayer({track, playlist, setPlaylist}){
    const {play, setPlay, position, setPosition, urlbase} = useApp();

    const handleBackSong = (e) => {
        if (playlist.length > 0) {
            setPlay(true);
            if (position == 1) {
                setPosition(playlist.length);
            }else{
                setPosition(prevPos => prevPos - 1);
            }
        }
    }
    const handlenextSong = (e) => {
        if (playlist.length > 0) {
            setPlay(true);
            if (position == playlist.length) {
                setPosition(1);
            }else{
                setPosition(prevPos => prevPos + 1);
            }
        }
    }
    const handlePlay = (e) => {
        if (play == false) {
            setPlay(true);
        }else{
            setPlay(false);
        }
    }
    const handlePause = (e) => {
        setPlay(true);
    }
    const handleEnd = (e) => {
        handlenextSong();
    }
    const handleClose = (e) => {
        setTimeout(() => {
            setPlay(false);
            setPlaylist([]);
        }, 200);
    }

    useEffect(() => {
        console.log(position);
        console.log(playlist[position-1].state);
        console.log(playlist[position-1].demo_track);
    }, [position]);

    return(
        <div className={"fixed-play-track slideInUp"} id="track_comp">
            <button className='btn_track btn_closed_track waves-effect text-center' onClick={handleClose}>
                <IoClose className='icon-md' />
            </button>
            <div className="row w-100 justify-center">
                <div className='row w-rep justify-center'>
                    <div className='row w-100 gap-3 justify-items-center justify-center mb-1'>
                        <div className="row m-w-600 justify-items-center">
                            <div className="col text-right">
                                <button className='btn_track waves-effect text-center' onClick={handleBackSong}>
                                    <IoPlaySkipBack className="icon-md" />
                                </button>
                            </div>
                            <div className="col sm-col-8 text-center">
                                <div className="img_inl">
                                    <img src={'/images/caratula.jpg'} className="img-profile-sm" />
                                </div>
                                <div className="text_inl text-left">
                                    <p className="tag_inl is_link clear-my">{playlist.length > 0 && playlist[position-1] ? limitText(playlist[position-1].title, 18) :  '-'}</p>
                                    <p className="stx_tt text-secondary sm-text clear-my">{playlist.length > 0 && playlist[position-1] ?  limitText(playlist[position-1].authors.toString(), 20) :  '-'}</p>
                                </div>
                            </div>
                            <div className="col text-left">
                                <button className='btn_track waves-effect text-center'  onClick={handlenextSong}>
                                    <IoPlaySkipForward className="icon-md" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        playlist.length > 0 && playlist[position-1] ?
                            (<AudioPlayer 
                                src={playlist[position-1].state == 'Grabada' ? urlbase+playlist[position-1].master_recording : playlist[position-1].state == 'Libre' || playlist[position-1].state == 'Apartada' ? urlbase+playlist[position-1].demo_track : urlbase+playlist[position-1].track}
                                autoPlay={play}
                                onPlay={handlePlay}
                                onPause={handlePause}
                                onEnd={handleEnd}
                                volume={100}
                                width={'100%'}
                                volumePlacement="top"
                                className={"reproductor_float"}
                                color={'#b7b7b7'}
                            />) : ''
                    }
                </div>
            </div>
            <div className='col text-left dnone'>
                <button className='btn_track waves-effect text-center'>
                    <IoListSharp className='icon-med' />
                </button>
            </div>
        </div>
    )
}
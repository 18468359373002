import {useState, useEffect, useRef} from 'react'
import Select from 'react-select'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {useNavigate} from 'react-router-dom'
import ProgressModal from "../ui/ProgressModal";
import {extractDate, limitText} from "../utility/utils"
import {IoChevronBack, IoReaderSharp, IoSettingsSharp, IoToday, IoCloudUploadOutline, IoCloseCircle, IoCheckmark, IoRefreshCircle} from "react-icons/io5";


export default function GrabadaForm({open, setOpen, isComplete, cancionId, isAcc, setMessage, idSucursal, isModal}) {
    const {session, loading, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [clients, setClients] = useState([]);
    const [editoras, setEditoras] = useState([]);
    const [isFailed, setFailed] = useState(true);
    const [socity, setSocity] = useState("");
    const file_p_demo = useRef();
    const file_c_reg = useRef();
    const file_m_grab = useRef();
    const file_c_contr = useRef();
    const navigate = useNavigate();
	const [song, setSong] = useState({
        title: "",
        folio_reg: "",
        track: "",
        certificate: "",
        final_title: "",
        cover_file: "",
        time_record: new Date(),
        interpreter: "",
        master_recording: "",
        perc_autor: "",
        perc_coautor: "",
        perc_editor: "",
        editor: "",
        contract: "",
        type_date_contract: 'Infinita',
        contract_date_start: "",
        contract_date_end: "",
        author_society: [],
        observation_rec: ""
    });
    const options = [
        { value: 'ASCAP', label: 'ASCAP' },
        { value: 'BMI', label: 'BMI' },
        { value: 'SACM', label: 'SACM' },
        { value: 'SESAC', label: 'SESAC' }
    ]

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setAlert(null);
            window.scrollTo(0,0);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (song.interpreter != "" && song.final_title != '' && song.perc_autor != '' && song.perc_coautor != '' && song.perc_editor != '' && song.editor != '') {
                let formData = new FormData();
                if (isAcc == 'Grabar_cero') {
                    formData.append('title', song.title);
                    formData.append('authors', song.authors);
                    formData.append('folio_reg', song.folio_reg);
                    formData.append('track', song.track);
                    formData.append('certificate', song.certificate);
                }
                formData.append('final_title', song.final_title);
                formData.append('interpreter', song.interpreter);
                formData.append('time_record', song.time_record);
                formData.append('master_recording', song.master_recording);
                formData.append('perc_autor', song.perc_autor);
                formData.append('perc_coautor', song.perc_coautor);
                formData.append('perc_editor', song.perc_editor);
                formData.append('editor', song.editor);
                formData.append('contract', song.contract);
                formData.append('type_date_contract', song.type_date_contract);
                formData.append('contract_date_start', song.contract_date_start);
                formData.append('contract_date_end', song.contract_date_end);
                formData.append('observation_rec', song.observation_rec);
                formData.append('accion', isAcc);
                if (song.author_society.length > 0) {
                    for (let i = 0; i < song.author_society.length; i++) {
                        formData.append('author_society[]', song.author_society[i]);
                    }
                }
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                Req.open("PUT", urlbase+"/app/update_song_grabada/"+cancionId, true);
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        if(res.clave && res.clave == "exito") {
                            if (isAcc == 'Apartar_edit') {
                                setMessage({text: "Tema grabado actualizado!", type:"done"});
                                isComplete();
                            }else{
                                if (isAcc == 'Apartar_cero') {
                                    setMessage({text: "Tema grabado con exito!", type:"done"});
                                    isComplete();
                                }else{
                                    setMessage({text: "Tema grabado con exito!", type:"done"});
                                    setTimeout(function() {
                                        navigate("/app/catalogo/vendidas");
                                    }, 1000);
                                }
                            }
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            isComplete();
                            setSong({ 
                                title: "",
                                final_title: "",
                                cover_file: "",
                                interpreter: "",
                                time_record: new Date(),
                                master_recording: "",
                                perc_autor: "",
                                perc_coautor: "",
                                perc_editor: "",
                                editor: "",
                                contract: "",
                                type_date_contract: 'Infinita',
                                contract_date_start: "",
                                contract_date_end: "",
                                author_society: [],
                                observation_rec: ""
                            });
                            window.scrollTo(0,0);
                            setIsLoad(true);
                            file_p_demo.current.value = '';
                            file_c_reg.current.value = '';
                            file_m_grab.current.value = '';
                            file_c_contr.current.value = '';
                        }else{
                            setAlert(res.mensaje);
                            setLoading(false);
                            button.disabled = false;
                        }
                    }
                }
                if (isAcc == 'Grabar_cero' && song.title == '') {
                    if (song.master_recording == '') {
                        setAlert("Debes completar la master grabacion");
                        setLoading(false);
                        button.disabled = false;
                    }else{
                        setAlert("Debes completar el titulo de la cancion");
                        setLoading(false);
                        button.disabled = false;
                    }
                }else{
                    if(isAcc == 'Grabar' && song.master_recording == '') {
                        setAlert("Debes completar la master grabacion");
                        setLoading(false);
                        button.disabled = false;
                    }else{
                        Req.send(formData);
                    }
                }
            }else{
            	setAlert("Completa los campos obligatorios");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadIntis = async (id) => {
    	const res = await fetch(urlbase+"/app/get_inti_fields", {headers:myHeaders()});
    	const response = await res.json();
        const clientsdb = response.artists;
        const editorasdb = response.editoras;
        if (clientsdb && clientsdb.length > 0) {
            setClients(clientsdb);
        }
        if (editorasdb && editorasdb.length > 0) {
            setEditoras(editorasdb);
        }
        file_p_demo.current.value = '';
        file_c_reg.current.value = '';
        file_m_grab.current.value = '';
        file_c_contr.current.value = '';
    }
    const loadSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_song/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.song;
        if (isAcc == 'Grabar_edit') {
            setSong({ 
                title: data.title,
                authors: data.authors,
                final_title: data.final_title,
                interpreter:data.interpreter._id, 
                time_record: new Date(data.time_record),
                perc_autor: data.perc_autor,
                perc_coautor:data.perc_coautor, 
                perc_editor:data.perc_editor, 
                editor: data.editor._id,
                type_date_contract: data.type_date_contract,
                contract_date_start: data.contract_date_start,
                contract_date_end: data.contract_date_end,
                author_society: data.author_society,
                observation_rec: data.observation_rec
            });
        }else if (isAcc == 'Grabar') {
                setSong({
                    title: data.title,
                    final_title: data.title,
                    cover_file: "",
                    interpreter: data.requested,
                    authors:data.authors,
                    state: data.state,
                    time_record: new Date(),
                    master_recording: "",
                    perc_autor: "",
                    perc_coautor: "",
                    perc_editor: "",
                    editor: "",
                    contract: "",
                    type_date_contract: 'Infinita',
                    contract_date_start: "",
                    contract_date_end: "",
                    author_society: "",
                    observation_rec: ""
                });
        }
        if (data.cover_file) {
            setImage(urlbase+data.cover_file);
        }
        setIsLoad(false);
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleChange = (e) => {
        setSong({...song, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleMulti = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSong({...song, ['author_society']: arr});
        setAlert(null);
    }
    const handleMp3 = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['master_recording']: e.target.files[0]});
        }else{
            setSong({...song, ['master_recording']: null});
        }
    }
    const handleTrack = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['track']: e.target.files[0]});
        }else{
            setSong({...song, ['track']: null});
        }
    }
    const handleCerti = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['certificate']: e.target.files[0]});
        }else{
            setSong({...song, ['certificate']: null});
        }
    } 
    const handleContract = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['contract']: e.target.files[0]});
        }else{
            setSong({...song, ['contract']: null});
        }
    }

    useEffect(() => {
        if (open) {
            setClients([]);
            setAlert(null);
            setSong({
                title: "",
                final_title: "",
                cover_file: "",
                interpreter: "",
                time_record: new Date(),
                master_recording: "",
                perc_autor: "",
                perc_coautor: "",
                perc_editor: "",
                editor: "",
                contract: "",
                type_date_contract: 'Infinita',
                contract_date_start: "",
                contract_date_end: "",
                author_society: [],
                observation_rec: ""
            });
            loadIntis();
            if (cancionId != null){
                loadSong(cancionId);
            }else{
                setIsLoad(false);
                setImage("/images/caratula.jpg");
            }
        }
    }, [open]);

    useEffect(() => {
        console.log(song);
    }, [song]);

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;
    
	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{isAcc == 'Grabar' ? 'Grabar Tema' : isAcc == 'Grabar_edit' ? 'Editar Grabacion' : isAcc == 'Grabar_cero' ? 'Crear y Grabar' : '-'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                {isAcc == 'Grabar_cero' ? <div className="row gap-1 w-100 mb-2">
                    <div className='row gap-1 w-100'>
                        <div className="col-1">
                            <IoSettingsSharp className="icon-md"/>
                        </div>
                        <div className="col text-left">
                            <strong className="tag_inl clear-my text-uppercase sm-text is_link">Datos de creación</strong>
                        </div>
                    </div>
                    <div className="row border-form p-2 w-100">
                        <div className="row gap-1 w-100">
                            <div className="col col-sm-12">
                                <label className="lb-inp-usm mb-1 w-100">
                                    <div className='row w-100 justify-between'>
                                        <span>Titulo</span>
                                        <span className='tg-requir'>obligatorio</span>
                                    </div>
                                </label>
                                <input type="text" className="inp_add in-requi" value={song.title} onChange={handleChange} name="title" placeholder="Titulo original" autoComplete="off"/>
                            </div>
                            <div className="col-5 col-sm-12">
                                <label className="lb-inp-usm mb-1">Folio de registro</label>
                                <input type="text" className="inp_add" value={song.folio_reg} onChange={handleChange} name="folio_reg" placeholder="FL656556565655" autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">Autor</label>
                            <input type="text" className="inp_add" value={song.authors} onChange={handleChange} name="authors" placeholder="Autor1,Autor2,Autor3" autoComplete="off"/>
                        </div>
                        <div className="row gap-1 w-100">
                            <div className="col-5 col-sm-12">
                                <label className="lb-inp-usm mb-1">Pista demo (opcional)</label>
                                <input type="file" ref={file_p_demo} className="inp_add" onInput={handleTrack} name="demo_track" accept=".mp3,audio/"/>
                            </div>
                            <div className="col col-sm-12">
                                <label className="lb-inp-usm mb-1">Certificado de registro (opcional)</label>
                                <input type="file" ref={file_c_reg} className="inp_add" onInput={handleCerti} name="certificate" accept=".pdf,.docx,.doc"/>
                            </div>
                        </div>
                    </div>
                </div> : ''}
                <div className="row w-100">
                    {isAcc == 'Grabar' || isAcc == 'Grabar_edit' ? <div className="row w-100">
                        <label className="lb-inp-usm mb-1">TEMA ELEGIDO</label>
                        <a className="tag-phone f_imp row justify-between justify-items-center w-100">
                            <div className="col">
                                <img src={image != '/images/caratula.jpg' ? image : urlbase+'/images/caratula.jpg'} className="img-profile-sm sm-img-med mt-p3 mr-1" />
                                <p className="tag_inl inl_bk_sm clear-my">
                                    <div className='is_link'>{limitText(song.title, 20)}</div>
                                    <div className='sm-text-75 text-secondary'>{song.authors != 'undefined' && song.authors != 'null' ? song.authors : '-'}</div>
                                </p>
                            </div>
                        </a>
                    </div>: ''}
                    <div className="row w-100 gap-2 my-1">
                        <div className="col col-sm-12">
                            <label className="lb-inp-usm mb-1">Fecha de grabacion</label>
                            <div className='position-relative w-100'>
                                <input type="date" className="inp_add inpd_m" value={extractDate(song.time_record)} onInput={handleChange} name="time_record"/>
                                <IoToday className="icn_fl_sel icon-sm showMovil" />
                            </div>
                        </div>
                        <div className="col col-sm-12" key={song.title}>
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Tambien conocida como</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="text" className="inp_add in-requi" defaultValue={isAcc == 'Grabar_cero' ? song.title : song.final_title} onChange={handleChange} name="final_title" placeholder="Titulo de lanzamiento *" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="row w-100 gap-1">
                        <div className="col-4 col-sm-12">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Interprete</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <div className='position-relative w-100'>
                                <select className="inp_add in-requi clear_sl_p" value={song.interpreter} name="interpreter" onInput={handleChange}>
                                    <option value="">Elegir...</option>
                                    {
                                        clients.map((client, ind) => {
                                            return (<option value={client._id}>{client.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col col-sm-12">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Master grabación</span>
                                    {isAcc == 'Grabar_edit' ? '' : <span className='tg-requir'>obligatorio</span>}
                                </div>
                            </label>
                            <input type="file" ref={file_m_grab} className={ isAcc == 'Grabar_edit' ? "inp_add" : "inp_add in-requi" } onInput={handleMp3} name="master_recording" accept=".mp3,audio/"/>
                        </div>
                    </div>
                    <div className="row w-100 gap-1 my-1">
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>% Autor</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="number" className="inp_add in-requi" value={song.perc_autor} onChange={handleChange} name="perc_autor" placeholder="% *" autoComplete="off"/>
                        </div>
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>% Coautor</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="number" className="inp_add in-requi" value={song.perc_coautor} onChange={handleChange} name="perc_coautor" placeholder="% *" autoComplete="off"/>
                        </div>
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>% Editor</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="number" className="inp_add in-requi" value={song.perc_editor} onChange={handleChange} name="perc_editor" placeholder="% *" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="row w-100 gap-1">
                        <div className="col-4 col-sm-12">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Editora</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <div className='position-relative w-100'>
                                <select className="inp_add in-requi clear_sl_p" value={song.editor} name="editor" onInput={handleChange}>
                                    <option value="">Elegir...</option>
                                    {
                                        editoras.map((editor, ind) => {
                                            return (<option value={editor._id}>{editor.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col col-sm-12">
                            <label className="lb-inp-usm mb-1">Sociedad Autoral</label>
                            <div className='position-relative w-100'>
                                <Select className='multi_sel' 
                                    name="author_society" 
                                    placeholder={"elegir..."} 
                                    onChange={handleMulti} 
                                    options={options}
                                    value={song.author_society && song.author_society.length > 0 ? song.author_society.map((value) => {return {value: value, label:value}}) : ""}
                                    isMulti
                                    />
                            </div>
                        </div>
                    </div>
                    <div className='row gap-1 py-2 w-100'>
                        <div className="col-1">
                            <IoReaderSharp className="icon-md"/>
                        </div>
                        <div className="col text-left">
                            <strong className="tag_inl clear-my text-uppercase sm-text is_link">Configuración del contrato</strong>
                        </div>
                    </div>
                    <div className="row w-100 gap-1">
                        <div className="col-12">
                            <label className="lb-inp-usm mb-1">Adjuntar (PDF, WORD)</label>
                            <input type="file" ref={file_c_contr} className="inp_add" onInput={handleContract} name="contract" accept=".pdf,.docx,.doc"/>
                        </div>
                        <div className="col">
                            <label className="lb-inp-usm mb-1">Fecha de contrato</label>
                            <div className='position-relative w-100'>
                                <select className="inp_add clear_sl_p" value={song.type_date_contract} name="type_date_contract" onInput={handleChange}>
                                    <option value="Infinita">Infinita</option>
                                    <option value="Definida">Definida</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {song.type_date_contract == 'Definida' ? <div className="row w-100 gap-1 my-2">
                    <div className="col">
                        <label className="lb-inp-usm mb-1">Fecha inicio contrato</label>
                        <input type="date" className="inp_add" value={extractDate(song.contract_date_start)} onInput={handleChange} name="contract_date_start"/>
                    </div>
                    <div className="col">
                        <label className="lb-inp-usm mb-1">Fecha inicio contrato</label>
                        <input type="date" className="inp_add" value={extractDate(song.contract_date_end)} onInput={handleChange} name="contract_date_end"/>
                    </div>
                </div> : ''}
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{loading ? 'SUBIENDO...': isAcc == 'Grabar_edit' ? 'Aplicar' : 'Liberar'}</button>
                        </div>
                    </div>
                </div> 
                { loading ? <div className='fixed-upload'>
                    <div className='center-content'>
                        <div className="row w-100">
                            <div className='row w-100 text-center justify-center p-3'>
                                <IoCloudUploadOutline className="icon-upld-anim"/>
                            </div>
                            <div className='row w-100 p-2'></div>
                            <div className="row w-100 cont_downloaad">
                                <div className="img_inl text-center">
                                    <img src={'/images/caratula.jpg'} className="img-profile-med" />
                                </div>
                                <div className="text_inl text-left">
                                    <p className="tag_tt_song clear-my">SUBIENDO...</p>
                                    <p className="stx_tt text-secondary sm-text clear-my mb-1">Pista de audio y Poster</p>
                                </div>
                                <div className="row w-100 text-center">
                                    <div className='barr_gains showBarr w-100'>
                                        <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                    </div>
                                </div>
                                {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                    <IoCheckmark className="checkisdone"/>
                                </span> : ''}
                                {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                            </div>
                            {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                                <p className="text-message p-2">Error al subir los archivos, mala conexion</p>
                                <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                            </div> : ''}
                            <div className='row w-100 text-center p-3'>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">No cierres esta ventana hasta que se complete la subida de archivos</p>
                            </div>
                        </div>
                    </div>
                </div> : ''}
            </div>
        </div>
	)
}
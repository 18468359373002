import { NavLink } from "react-router-dom";
import {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {IoAddSharp, IoEllipsisVerticalSharp, IoCreate, IoRemoveCircle, IoCheckmarkCircleOutline, IoRadioOutline, IoPlaySharp, IoCloudDownloadSharp, IoChevronBack} from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import Modal from "../../components/ui/Modal";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import CancionForm from "../../components/forms/CancionForm"
import LiberarForm from "../../components/forms/LiberarForm"
import ProgressIntern from "../../components/ui/ProgressIntern";
import Song from "../../components/views/Song";
import {searchTable, fNumber, extractDate} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import DownloadFile from "../../components/ui/DownloadFile";

export default function Creaciones({type}){
    const {position, play, setPlay, setPosition, playlist, setPlaylist, setLoading, urlbase, myHeaders} = useApp();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [songs, setSongs] = useState([]);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [opens, setOpenes ] = useState(false);
    const [opend, setOpend] = useState(false);
    const [opendown, setOpendown] = useState(false);
    const [openl, setOpenl] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [idclient, setIdClient] = useState(null);
    const [idopsel, setIdOpsel] = useState(null);
    const [songdown, setSongDown] = useState(null);
    const [idview, setIdView] = useState(null);
    const tableSer = useRef(null);
    const section = 'Creada';

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed(e);
        }, 200);
    }
    const abrirModal = () => {
        setOpen(true);
    }
    const handleClosed = (e) => {
        e.stopPropagation();
        setOpen(false);
        setOpend(false);
        setOpenes(false);
        setOpenl(false);
        setIdRef(null);
        setIdOpsel(null);
        setIdClient(null);
        setIdView(null);
        setOpendown(false);
        setSongDown(null);
    }
    const loadSongs = async () => {
        try {
            setIdRef(null);
            setIdClient(null);
            setLoading(true);
            const response = await fetch(urlbase+"/app/get_songs/creadas", {
                method: 'GET',
                headers: myHeaders(),
            })
            const data = await response.json();
            setSongs(data);
            setIsLoad(true);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const loadPlaylist = async () => {
        if (songs.length > 0) {
            setPlay(true);
            setPlaylist(songs);
            setPosition(1);
        }else{
            setMessage({text: "No hay canciones para reproducir", type:"error"});
        }
    }
    const handleSend = async (e) => {
        try {
            setIdRef(null);
            setIdClient(null);
            let id = e.currentTarget.id;
            let sg = null;
            let records = await songs.map((songdb) => {
                if(songdb._id == id) {
                    sg = songdb;
                }
            });
            setSongDown(sg);
            setOpendown(true);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const handlePlay = async (e) => {
        if (songs.length > 0) {
            if (play == false) {
                setPlaylist(songs);
                setPosition(Number(e.currentTarget.id));
                setPlay(true);
            }else{
                if (position == Number(e.currentTarget.id)) {
                    setPlaylist([]);
                    setPosition(1);
                    setPlay(false);
                }else{
                    setPlaylist(songs);
                    setPosition(Number(e.currentTarget.id));
                    setPlay(true);
                }
            }
        }else{
            setMessage({text: "No hay canciones para reproducir", type:"error"});
        }
    }
    const delClient = async (e, id) => {
        try {
            console.log(idclient);
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_song/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setSongs(songs.filter((songdb) => songdb._id !== idref));
                setMessage({text: "Cancion eliminada", type:"error"});
                setOpend(false);
                setIdRef(null);
            }else{
                setAlert(data.mensaje);
            }
            setLoading(false);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleEdit = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpen(true);
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }
    const handleLibre = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpenl(true);
    }
    const handleOptions = async (e) => {
        setIdOpsel(e.currentTarget.id);
        setIdClient(e.currentTarget.id);
        setIdRef(e.currentTarget.id);
        setOption(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }

    useEffect(() => {
        setSongs([]);
        setPlay(false);
        setMessage(false);
        loadSongs();
        setIsLoad(false);
    }, [type])


    useEffect(() => {
        if (option == false) {
            setIdOpsel(null);
        }
    }, [option])


    if (!isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-2">
                    <div className="col-2 ocultar-sm">
                        <h1 className="title-lg my-1">Creaciones</h1>
                    </div>
                    <div className="col-4 col-sm-9 py-2">
                        <input type="search" className="inp_add" placeholder="Buscar..." onInput={handleSearch} onClick={handleSelect}/>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">Creacion</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row w-100">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th className="ocultar-sm"> # </th>
                                <th className="text-center">Play</th>
                                <th>Titulo</th>
                                <th className="ocultar-sm">Autores</th>
                                <th className="ocultar-sm">Libre?</th>
                                <th className="text-center">Acc</th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {songs.map((song, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td className="ocultar-sm">{ind+1}</td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn_play waves-effect" id={ind+1} onClick={handlePlay}>{position == ind+1 && play == true && playlist[position-1].state == section ? <IoRadioOutline className="icon-ply pulse" /> : <IoPlaySharp className="icon-ply" />}</button>
                                    </td>
                                    <td>
                                        <div className="row gap-1 justify-items-center">
                                            <div className="col-2">
                                                <img src={song.cover_file != null ? urlbase+song.cover_file : urlbase+'/images/caratula.jpg'} className="img-profile-sm" />
                                            </div>
                                            <div className="col text-left">
                                                <p className="tag_inl is_link clear-my" id={song._id} onClick={handleView}>{song.title}</p>
                                                <p className="stx_tt text-secondary sm-text showMovil clear-my">{song.authors}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="ocultar-sm">
                                        <span className="sm-text color-secondary">{song.authors}</span>
                                    </td>
                                    <td className="ocultar-sm">
                                        <button className="btn btn-sm btn-primary ocultar-sm" id={song._id} onClick={handleLibre}>
                                            <div className="row w-100 align-items-center">
                                                <IoCheckmarkCircleOutline className="icon-btn mr-m" />
                                                <span className="col">Marcar como libre</span>
                                            </div>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-primary mr-1 ocultar-sm" id={song._id} onClick={handleEdit}>Editar</button>
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={song._id} onClick={handleDelete}>Eliminar</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={song._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <Modal open={opendown} setOpen={setOpendown} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <DownloadFile open={opendown} setOpen={setOpendown} songref={songdown} setMessage={setMessage}/>
            </Modal>
            <Modal open={opens} setOpen={setOpenes} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <Song open={opens} setOpen={setOpenes} songId={idview}/>
            </Modal>
            <Modal open={open} setOpen={setOpen} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <CancionForm open={open} setOpen={setOpen} isComplete={loadSongs} setIdCancion={setIdClient} cancionId={idclient} type={'Cancion'} setMessage={setMessage}/>
            </Modal>
            <Modal open={openl} setOpen={setOpenl} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <LiberarForm open={openl} setOpen={setOpenl} isComplete={loadSongs} cancionId={idclient} isAcc={'Liberar'} setIdCancion={setIdClient} setMessage={setMessage}/>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">¿Eliminar esta cancion?</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body text-center sm-text-left">
                    <p className="color-red">Esta cancion se eliminara permanentemente de la aplicacion</p>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delClient}>Eliminar</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption}>
                <ul className="list">
                    <div className="item-list waves-effect" id={idclient} onClick={handleSend}>
                        <div className="cont-icon">
                            <IoCloudDownloadSharp className="icon_item"/>
                        </div>
                        <span className="tex_item">Descargar</span>
                    </div>
                    <div className="item-list waves-effect" id={idclient} onClick={handleLibre}>
                        <div className="cont-icon">
                            <IoCheckmarkCircleOutline className="icon_item"/>
                        </div>
                        <span className="tex_item">Liberar</span>
                    </div>
                    <div className="item-list waves-effect" id={idclient} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">Editar</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">Eliminar</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
import {useState, useEffect, useRef} from 'react'
import {IoSend, IoArrowRedoSharp, IoEllipsisVerticalSharp, IoAddSharp, IoCreate, IoRemoveCircle, IoPlay, IoReaderSharp, IoPlaySharp, IoRadioOutline, IoChevronBack } from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import Modal from "../../components/ui/Modal";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import LiberarForm from "../../components/forms/LiberarForm";
import ApartarForm from "../../components/forms/ApartarForm"
import ProgressIntern from "../../components/ui/ProgressIntern";
import Song from "../../components/views/Song";
import {searchTable} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import DownloadFile from "../../components/ui/DownloadFile";

export default function Libres({type}){
    const {position, play, setPlay, setPosition, playlist, setPlaylist, setLoading, urlbase, myHeaders} = useApp();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [songs, setSongs] = useState([]);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [openl, setOpenl] = useState(false);
    const [opens, setOpenes ] = useState(false);
    const [opend, setOpend] = useState(false);
    const [opendown, setOpendown] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [idclient, setIdClient] = useState(null);
    const [idview, setIdView] = useState(null);
    const [songdown, setSongDown] = useState(null);
    const [accion, setAccion] = useState('Liberar_edit');
    const tableSer = useRef(null);
    const section = 'Libre';

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed(e);
        }, 200);
    }
    const abrirModal = () => {
        setAccion('Liberar_cero');
        setOpenl(true);
    }
    const handleClosed = (e) => {
        e.stopPropagation();
        setOpen(false);
        setOpend(false);
        setOpenes(false);
        setIdRef(null);
        setIdClient(null);
        setOpenl(false);
        setIdView(null);
        setOpendown(false);
        setSongDown(null);
        setAccion('Liberar_edit');
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }
    const loadSongs = async () => {
        try {
            setIdRef(null);
            setIdClient(null);
            setLoading(true);
            const response = await fetch(urlbase+"/app/get_songs/libres", {
                method: 'GET',
                headers: myHeaders(),
            })
            const data = await response.json();
            setSongs(data);
            setIsLoad(true);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const loadPlaylist = async () => {
        if (songs.length > 0) {
            setPlay(true);
            setPlaylist(songs);
            setPosition(1);
        }else{
            setMessage({text: "No hay canciones para reproducir", type:"error"});
        }
    }
    const handlePlay = async (e) => {
        if (songs.length > 0) {
            if (play == false) {
                setPlaylist(songs);
                setPosition(Number(e.currentTarget.id));
                setPlay(true);
            }else{
                if (position == Number(e.currentTarget.id)) {
                    setPlaylist([]);
                    setPosition(1);
                    setPlay(false);
                }else{
                    setPlaylist(songs);
                    setPosition(Number(e.currentTarget.id));
                    setPlay(true);
                }
            }
        }else{
            setMessage({text: "No hay canciones para reproducir", type:"error"});
        }
    }
    const delClient = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_song/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setSongs(songs.filter((songdb) => songdb._id !== idref));
                setMessage({text: "Cancion eliminada", type:"error"});
                setOpend(false);
                setIdRef(null);
            }else{
                setAlert(data.mensaje);
            }
            setLoading(false);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleSend = async (e) => {
        try {
            setIdRef(null);
            setIdClient(null);
            let id = e.currentTarget.id;
            let sg = null;
            let records = await songs.map((songdb) => {
                if(songdb._id == id){
                    sg = songdb;
                }
            });
            setSongDown(sg);
            setOpendown(true);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const handleEdit = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpenl(true);
    }
    const handleApartar = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpen(true);
    }
    const handleOptions = async (e) => {
        setIdRef(e.currentTarget.id);
        setIdClient(e.currentTarget.id);
        setOption(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }
    const shareFILE = async (file) => {
        try {
            const shareData = {
                files: [file],
            };
            if (navigator.canShare && navigator.canShare(shareData)) {
                await navigator.share(shareData);
            } else {
                console.log('Tu navegador no admite compartir archivos.');
            }
        } catch (error) {
            console.log('Error al compartir el archivo:', error);
        }
    }
    const handleDownCert = async (e) => {
        setIdRef(null);
        setIdClient(null);
        let id = e.currentTarget.id;
        let sg = null;
        let records = await songs.map((songdb) => {
            if(songdb._id == id){
                sg = songdb;
            }
        });
        if (sg.certificate && sg.certificate != '') {
            const win = window.open(urlbase+sg.certificate, '_blank');
            if (win != null) {
                win.focus();
            }
        }else{
            setMessage({text: "Cancion sin certiicado", type:"error"});
        }
    }
    

    useEffect(() => {
        setSongs([]);
        setPlay(false);
        setMessage(false);
        loadSongs();
        setIsLoad(false);
    }, [type])


    if (!isload) return <div className="fadeIn w-100">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn w-100">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-1">
                    <div className="col-5 col-sm-9">
                        <div className="row w-100 justify-items-center gap-2">
                            <div className="col">
                                <input type="search" className="inp_add" placeholder="Buscar libres..." onInput={handleSearch} onClick={handleSelect}/>
                            </div>
                            <div className="col-4 ocultar-sm">
                                <button className={'btn btn-sm btn-outline-primary waves-effect link-tab act-tab'} onClick={loadPlaylist}>
                                    <IoPlay className="icon-md icn-mov align-icon"/> <span className='txt_tab'> Reproducir 
                                        <span className="ocultar-sm">todas</span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">Promocion</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row my-2">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th> # </th>
                                <th className="ocultar-sm">Folio</th>
                                <th className="text-center">Pista</th>
                                <th>Titulo</th>
                                <th th className="text-center ocultar-sm">Cert.</th>
                                <th className="ocultar-sm">Autors</th>
                                <th className="text-center ocultar-sm">% participacion</th>
                                <th className="text-center ocultar-sm">Enviada</th>
                                <th className="ocultar-sm">Enviar</th>
                                <th className="ocultar-sm">Libre?</th>
                                <th className="text-center">Acc</th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {songs.map((song, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td>{ind+1}</td>
                                    <td className="ocultar-sm"><p className="sm-text text-secondary">{song.folio_reg == '' ? '-' : song.folio_reg}</p></td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn_play waves-effect is_libr" id={ind+1} onClick={handlePlay}>
                                            {position == ind+1 && play == true && playlist[position-1].state == section ? <IoRadioOutline className="icon-ply pulse" /> : <IoPlaySharp className="icon-ply" />}
                                        </button>
                                    </td>
                                    <td>
                                        <div className="row gap-1 justify-items-center">
                                            <div className="col-2">
                                                <img src={song.cover_file != null ? urlbase+song.cover_file : urlbase+'/images/caratula.jpg'} className="img-profile-sm" />
                                            </div>
                                            <div className="col text-left">
                                                <p className="tag_inl is_link clear-my" id={song._id} onClick={handleView}>{song.title}</p>
                                                <p className="stx_tt text-secondary sm-text showMovil clear-my">{song.authors}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center ocultar-sm">
                                        {song.certificate && song.certificate != '' ? <a className="tag-phone" href={urlbase+song.certificate} target="_blank">
                                            <IoReaderSharp  className="icon-phone-tg"/>
                                        </a> : <span className="tag-phone opc-5" target="_blank">
                                                <IoReaderSharp  className="icon-phone-tg"/>
                                               </span>}
                                        
                                    </td>
                                    <td className="ocultar-sm">
                                        <span className="sm-text color-secondary">{song.authors == '' ? '-' : song.authors}</span>
                                    </td>
                                    <td className="text-center ocultar-sm">
                                        <span className="sm-text color-secondary">{song.perc_parti == '' ? '-' : song.perc_parti+'%'}</span>
                                    </td>
                                    <td className="text-center ocultar-sm">
                                        <span className="sm-text color-secondary">5 veces</span>
                                    </td>
                                    <td className="ocultar-sm">
                                        <button className="btn btn-sm btn-outline-primary" data-name={song.title} id={song._id} onClick={handleSend}>
                                            <div className="row w-100 align-items-center">
                                                <IoSend className="icon-btn mr-m" />
                                                <span className="col">Enviar</span>
                                            </div>
                                        </button>
                                    </td>
                                    <td className="ocultar-sm">
                                        <button className="btn btn-sm btn-primary" id={song._id} onClick={handleApartar}>
                                            <div className="row w-100 align-items-center">
                                                <IoArrowRedoSharp className="icon-btn mr-m" />
                                                <span className="col">APARTAR</span>
                                            </div>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-primary mx-1 ocultar-sm" id={song._id} onClick={handleEdit}>Editar</button>
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={song._id} onClick={handleDelete}>Eliminar</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={song._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <Modal open={opendown} setOpen={setOpendown} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <DownloadFile open={opendown} setOpen={setOpendown} songref={songdown} setMessage={setMessage}/>
            </Modal>
            <Modal open={opens} setOpen={setOpenes} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <Song open={opens} setOpen={setOpenes} songId={idview}/>
            </Modal>
            <Modal open={openl} setOpen={setOpenl} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <LiberarForm open={openl} setOpen={setOpenl} isComplete={loadSongs} isEdit={true} isAcc={accion} cancionId={idclient} setMessage={setMessage}/>
            </Modal>
            <Modal open={open} setOpen={setOpen} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <ApartarForm open={open} setOpen={setOpen} isComplete={loadSongs} isAcc={'Apartar'} cancionId={idclient} setMessage={setMessage}/>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">¿Eliminar esta cancion?</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body text-center sm-text-left">
                    <p className="color-red">Esta cancion se eliminara permanentemente de la aplicacion</p>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delClient}>Eliminar</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption}>
                <ul className="list">
                    <div className="item-list waves-effect" id={idclient} onClick={handleSend}>
                        <div className="cont-icon">
                            <IoSend className="icon_item"/>
                        </div>
                        <span className="tex_item">Enviar</span>
                    </div>
                    <div className="item-list waves-effect" id={idclient} onClick={handleApartar}>
                        <div className="cont-icon">
                            <IoArrowRedoSharp className="icon_item"/>
                        </div>
                        <span className="tex_item">Apartar</span>
                    </div>
                    <div className="item-list waves-effect" id={idclient} onClick={handleDownCert}>
                        <div className="cont-icon">
                            <IoReaderSharp className="icon_item"/>
                        </div>
                        <span className="tex_item">Certificado</span>
                    </div>
                    <div className="item-list waves-effect" id={idclient} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">Editar</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">Eliminar</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
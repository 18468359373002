import { useParams, Link } from "react-router-dom";
import { IoEarth, IoClose, IoReorderThree, IoCaretDown, IoCameraReverse, IoPowerSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import {useApp} from "../../context/appContext";

export default function Header(){
    const {sucursalId} = useParams();
    const {clearSession, urlbase, loadSessionToken} = useApp();
    const [openLogout, setOpenLogout] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [user, setUser] = useState({name:"", rol:"", photo:''});

    const handleMenuLogout = (e) => {
        e.stopPropagation();
        if (openLogout)
            setOpenLogout(false);
        else
            setOpenLogout(true);
    }
    const handleMenu = (e) => {
        e.stopPropagation();
        let mn = document.getElementById("menu_slide");
        if (openMenu){
            mn.classList.remove('show');
            mn.classList.remove('bounceInLeft');
            setOpenMenu(false);
        }else{
            mn.classList.add('show');
            mn.classList.add('bounceInLeft');
            setOpenMenu(true);
        }
    }
    const handleLogout = (e) => {
        e.stopPropagation();
        setTimeout(function(argument) {
            clearSession();
        }, 200);
    }
   

    useEffect(() => {
        const session = loadSessionToken();
        let foto = '/images/usuario.png';
        if (session.user.photo) {
            foto = urlbase+session.user.photo;
        }
        setUser({name:session.user.name, rol:session.user.rol, photo:foto});
        const onClick = () => {
            let mn = document.getElementById("menu_slide");
            setOpenLogout(false);
            setOpenMenu(false);
            mn.classList.remove('show');
            mn.classList.remove('bounceInLeft');
        };
        document.addEventListener("click", onClick);
        return () => {
            document.removeEventListener("click", onClick);
        };
    }, [])

    return(
        <header className="header">
            <Link className="cont_logo" to="/app">
                <img src="/images/logo_hori.png" className="logo_empresa ocultar-sm"/>
                <img src="/images/logo_iso.png" className="logo_empresa_mov showMovil"/>
            </Link>
            <div className="cont-icons-head ocultar-sm">
                <div className="row">
                    <div className="col">
                        <button className="btn-head text-center waves-effect waves-light">
                            <IoEarth className="icon-head" />
                            <span className="cl-t-he fs-75 text-uppercase">Notific.</span>
                        </button>
                    </div>
                </div>
            </div>  
            <div className="user_opc" onClick={handleMenuLogout}>
                <span to="#" className="nombre_user"> {user.name} <p className="tp_almn">{user.rol}</p></span>
                <div className="list_p">
                    <img src={user.photo} className="img_user" id="avatar-app"/>
                </div>
                <IoCaretDown className="icon_Sa"/>
            </div>
            <div className={openLogout ? 'menu_salir' : 'menu_salir dnone'}>
                <div className="list_opc">
                    <Link to={user.rol != 'Vendedor' ? '/app/foto_perfil' : '/sucursal/'+sucursalId+'/empleado/foto_perfil'} className="item_opc_salir waves-effect">
                        <span className="link_text">Cambiar foto de perfil</span>
                        <IoCameraReverse className="icon_list"/>
                    </Link>
                    <div className="item_opc_salir waves-effect" onClick={handleLogout}>
                        <span className="link_text">Salir</span>
                        <IoPowerSharp className="icon_list"/>
                    </div>
                </div>
            </div>
            <span className="icon-menu waves-effect waves-light">
                {openMenu ? (<IoClose className="icn_inc_m" onClick={handleMenu}/>) : (<IoReorderThree className="icn_inc_m" onClick={handleMenu}/>)}
            </span>
        </header>
    )
}
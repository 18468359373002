import {useState, useEffect, useRef} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import CroppieImage from "../ui/CroppieImage";
import {Accordion, AccordionItem } from '@szhsin/react-accordion';
import AccordionEditHead from "../../components/ui/AccordionEditHead";
import {extractDate, separarImagen} from "../utility/utils"
import {IoImages, IoChevronBack, IoToday, IoCloseCircle, IoCheckmark, IoCloudUploadOutline, IoRefreshCircle } from "react-icons/io5";
import Compress from 'compress.js'


export default function CancionForm({open, setOpen, isComplete, cancionId, setIdCancion, type, setMessage, idSucursal}) {
    const {session, loading, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [image_f, setImagef] = useState(null);
    const [s_file, setSFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isFailed, setFailed] = useState(true);
    const inputMp3 = useRef();
    const reader = new FileReader();
	const [song, setSong] = useState({
        title: "",
        authors:"",
        track: "",
        metadata: null,
        state: "Creada",
        creation_date: new Date(),
        song_lyrics: "",
        cover_file: ""
    });
    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setAlert(null);
            setIdCancion(null);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (song.title != ""){
                let formData = new FormData();
                formData.append('title', song.title);
                formData.append('authors', song.authors);
                formData.append('state', song.state);
                formData.append('creation_date', song.creation_date);
                formData.append('track', song.track);
                formData.append('song_lyrics', song.song_lyrics);
                formData.append('cover_file', song.cover_file);
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                if (cancionId) {
                    Req.open("PUT", urlbase+"/app/update_song/"+cancionId, true);
                }else{
                    Req.open("POST", urlbase+"/app/save_song", true);
                }
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        if(res.clave && res.clave == "exito") {
                            if (cancionId) {
                                setMessage({text: type+" actualizado", type:"done"});
                            }else{
                                setMessage({text: type+" guardada", type:"done"});
                            }
                            setIdCancion(null);
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            isComplete();
                            setSong({ title: "", authors:"", track: "", state: "Libre", creation_date: new Date(), song_lyrics: "", cover_file: ""});
                            window.scrollTo(0,0);
                        }else{
                            setAlert(res.mensaje);
                        }
                    }
                }
                if (song.track == '') {
                    setAlert("Debes cargar la pista");
                    setLoading(false);
                    button.disabled = false;
                }else{
                    Req.send(formData);
                }
            }else{
            	setAlert("Completa los campos obligatorios");
                setLoading(false);
                button.disabled = false;
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_song/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.song;
        setSong({ title: data.title, authors:data.authors, state: data.state, creation_date: new Date(data.creation_date), song_lyrics:data.song_lyrics});
        if (data.cover_file) {
            setImage(urlbase+data.cover_file);
        }
    }
    const completeImage = (croppedImage) => {
        if (croppedImage) {
            setImagef(croppedImage);
        }
    }
    const handleChange = (e) => {
        setSong({...song, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleMp3 = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['track']: e.target.files[0]});
        }else{
            setSong({...song, ['track']: null});
        }
    } 
    const handleChangeFile = (e) => {
        let file = e.target.files[0];
        var compress = new Compress();
        var files = [file];
        compress.compress(files, {size: 2,quality: 0.85,maxWidth: 800,maxHeight: 800,resize: true})
        .then((images) => {
            var img = images[0];
            var imagen = img.prefix+img.data.toString();
            var file_t = separarImagen(imagen);
            if (file_t) {
                reader.onload = function (e) {
                    setImage(e.target.result);
                }
                reader.readAsDataURL(file_t);
                setSFile(true);
            }
        });
    }

    useEffect(() => {
        if (open) {
            inputMp3.current.value = "";
            setSong({ title: "", authors:"", track: "", state: "Libre", creation_date: new Date(), song_lyrics: "", cover_file: ""});
            if (cancionId != null){
                loadSong(cancionId);
            }else{
                setImage("/images/caratula.jpg");
            }
        }
    }, [open]);

    useEffect(() => {
        if (s_file) {
            setSong({...song, ['cover_file']: image_f});
        }
    }, [image_f]);

    useEffect(() => {
        if (progress) {
            console.log(progress);
        }
    }, [progress]);

	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{cancionId != null ? 'Editar creacion' : 'Nueva creacion'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    {open ? <Accordion className='accordion cl-mg-top w-100' transition={true} transitionTimeout={250}>
                        <AccordionItem header={<AccordionEditHead titulo={'Poster de la cancion'} initialEntered={false}/>} >
                            {({state}) => (<>
                                <div className="row w-100">
                                    {state.isEnter ? <CroppieImage open={state.isEnter} image={image} aspect={4 / 4} getCompleteCorp={completeImage} /> : ''}
                                    <div className="row my-1 text-center w-100">
                                        <button className="btn-icon btn-sm w-100 btn-primary waves-effect waves-light">
                                            <input type="file" className="file-in-button" onChange={handleChangeFile} accept="image/*"/>
                                            <div className="row w-100 align-items-center text-center">
                                                <IoImages   className="icon-btn mr-m" />
                                                <span className="col">Elegir</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>)}
                        </AccordionItem>
                    </Accordion> : ''}
                    <div className="row w-100 my-1">
                        <div className="row my-1 w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Pista demo</span>
                                    {cancionId ? '' : <span className='tg-requir'>obligatorio</span>}
                                </div>
                            </label>
                            <input type="file" className={ cancionId ? "inp_add" : "inp_add in-requi" } ref={inputMp3} onInput={handleMp3} name="track" accept=".mp3,.m4a,audio/"/>
                        </div>
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Titulo</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="text" className="inp_add in-requi" value={song.title} onChange={handleChange} name="title" placeholder="Titulo *" autoComplete="off"/>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">Autor</label>
                            <input type="text" className="inp_add" value={song.authors} onChange={handleChange} name="authors" placeholder="Autor1,Autor2,Autor3" autoComplete="off"/>
                        </div>
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1">Fecha de creacion</label>
                            <div className='position-relative w-100'>
                                <input type="date" className="inp_add inpd_m" value={extractDate(song.creation_date)} onInput={handleChange} name="creation_date"/>
                                <IoToday className="icn_fl_sel icon-sm showMovil" />
                            </div>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">Letra</label>
                            <textarea className="inp_add h-200" onChange={handleChange} value={song.song_lyrics} name="song_lyrics" placeholder="Letra de la cancion (opcional)"></textarea>
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{ loading ? 'SUBIENDO...': cancionId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
            { loading ? <div className='fixed-upload'>
                <div className='center-content'>
                    <div className="row w-100">
                        <div className='row w-100 text-center justify-center p-3'>
                            <IoCloudUploadOutline className="icon-upld-anim"/>
                        </div>
                        <div className='row w-100 p-2'></div>
                        <div className="row w-100 cont_downloaad">
                            <div className="img_inl text-center">
                                <img src={'/images/caratula.jpg'} className="img-profile-med" />
                            </div>
                            <div className="text_inl text-left">
                                <p className="tag_tt_song clear-my">SUBIENDO...</p>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">Pista de audio y Poster</p>
                            </div>
                            <div className="row w-100 text-center">
                                <div className='barr_gains showBarr w-100'>
                                    <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                </div>
                            </div>
                            {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                <IoCheckmark className="checkisdone"/>
                            </span> : ''}
                            {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                        </div>
                        {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                            <p className="text-message p-2">Error al subir los archivos, mala conexion</p>
                            <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                        </div> : ''}
                        <div className='row w-100 text-center p-3'>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">No cierres esta ventana hasta que se complete la subida de archivos</p>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
	)
}
import {useState, useEffect} from 'react'
import {useApp} from "../../context/appContext";
import {IoChevronBack, IoReaderSharp, IoMusicalNotes, IoMusicalNoteSharp, IoMusicalNoteOutline, IoCheckmarkCircleSharp, IoSend } from "react-icons/io5";
import { AudioPlayer } from 'react-audio-play';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import AccordionHeader from "../../components/ui/AccordionHeader";
import AccordionEditHead from "../../components/ui/AccordionEditHead";
import {extractHour, extractDateFormatR, extractDateR} from "../../components/utility/utils";

export default function Song({songId, setOpen}){
    const {setLoading, urlbase, myHeaders} = useApp();
    const [message, setMessage] = useState(false);
    const [openc, setOpenc] = useState(false);
    const [sharings, setSharings] = useState([]);
    const [song, setSong] = useState({
        final_title: "",
        title: "",
        authors:"",
        track: "",
        metadata: null,
        state: "Creada",
        creation_date: new Date(),
        song_lyrics: "",
        cover_file: "",
        folio_reg: "",
        coauthor: "",
        perc_parti: "",
        demo_track: "",
        certificate: "",
        requested: "",
        end_time_requested: "",
        interpreter: "",
        master_recording: "",
        perc_autor: "",
        perc_coautor: "",
        perc_editor: "",
        editor: "",
        contract: "",
        type_date_contract: 'Infinita',
        contract_date_start: "",
        contract_date_end: "",
        author_society: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
        }, 200);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpenc(false);
    }
    const loadSong = async (id) => {
        const res = await fetch(urlbase+"/app/get_song/"+id, {
            method: 'GET',
            headers:myHeaders()
        })
        const response = await res.json();
        const data = response.song;
        const sharingsDb = response.sharings;
        setSong(data);
        if (sharingsDb.length > 0) {
            setSharings(sharingsDb);
        }
    }

    useEffect(() => {
        if (songId) {
            loadSong(songId);
        }
    }, [songId]);

    return(
        <div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>INFO CANCION</h5>
            </div>
            <div className='modal_body'>
                <div className="row w-100">
                    <div className="row justify-center w-100">
                        <div className='w-100 sm-py-2'></div>
                        <div className='w-100 sm-py-2'></div>
                        <div className="col-8 text-center col-sm-12">
                            <img src={song.cover_file != null ? urlbase+song.cover_file : urlbase+'/images/caratula.jpg'} className="img-profile" />
                            <div className='row w-100'>
                                <div className='w-100 text-center my-1'>
                                    {song.state == 'Grabada' ? <span className='tg-state-gra'>
                                        <IoMusicalNotes className="icn-sate" />
                                        {song.state}
                                    </span> : song.state == 'Apartada' ? <span className='tg-state-apa'>
                                        <IoMusicalNoteSharp className="icn-sate" />
                                        {song.state}
                                    </span> : song.state == 'Libre' ? <span className='tg-state-lib'>
                                        <IoMusicalNoteOutline className="icn-sate" />
                                        {song.state}
                                    </span> : <span className='tg-state-cre'>
                                        <IoCheckmarkCircleSharp  className="icn-sate" />
                                        {song.state}
                                    </span>}
                                </div>
                                <h2 className="w-100">{song.title}</h2>
                                <p className="text-secondary clear-my w-100 mb-2">{song.authors}</p>
                            </div>
                            <div className='row w-100'>
                                    {song.state == 'Creada' ?
                                        <AudioPlayer 
                                            src={urlbase+song.track}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        />
                                    : song.state == 'Libre' || song.state == 'Apartada' ? 
                                        <AudioPlayer 
                                            src={urlbase+song.demo_track}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        />
                                    : song.state == 'Grabada' ? 
                                        <AudioPlayer 
                                            src={urlbase+song.master_recording}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        /> 
                                    : 
                                        <AudioPlayer 
                                            src={urlbase+song.track}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        />
                                    }
                            </div>
                            <Accordion className='accordion' transition={true} transitionTimeout={250}>
                                <AccordionItem header={<AccordionHeader titulo={'Informacion'} initialEntered={true}/>} >
                                    <div className='row w-100'>
                                        <div className="list-ficha w-100 px-3 md-p-2 text-left">
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">CREADA</strong>
                                                <span className="col text-right text-secondary">{(extractDateR(song.created))} {extractHour(song.created)}</span>
                                            </p>
                                            <p className="row w-100 justify-between my-1">
                                                <strong className="col sm-mov-85">MODIFICADA</strong>
                                                <span className="col text-right text-secondary">{(extractDateR(song.modified))} {extractHour(song.modified)}</span>
                                            </p>
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">FECHA DE LANZAMIENTO</strong>
                                                <span className="col text-right text-secondary">{(extractDateR(song.creation_date))}</span>
                                            </p>
                                            <p className="row w-100 justify-between my-1">
                                                <strong className="col sm-mov-85">FOLIO</strong>
                                                <span className="col text-right is_link">{song.folio_reg}</span>
                                            </p>
                                            <p className="row w-100 justify-between my-1">
                                                <strong className="col sm-mov-85">ESTADO</strong>
                                                <span className="col text-right is_link">{song.state}</span>
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItem>
                                <AccordionItem header={<AccordionHeader titulo={'Certificado'}/>}>
                                    <div className='row w-100'>
                                        {song.certificate && song.certificate != '' ? <a className="tag-phone tag-phone-mv w-100" href={urlbase+song.certificate} target="_blank">
                                            <IoReaderSharp  className="icon-phone-tg mr-1"/>
                                            <span className="phone-number is_link sm-py-3">Descargar certificado</span>
                                        </a> : <span className="sm-text sm-py-3">No existe el certificado</span>}
                                    </div>
                                </AccordionItem>
                                <AccordionItem header={<AccordionHeader titulo={'Grabación'}/>}>
                                    <div className='row w-100'>
                                        <div className="list-ficha w-100 px-3 text-left">
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">TITULO FINAL</strong>
                                                <span className="col text-right text-secondary">{song.final_title ? song.final_title : '-'}</span>
                                            </p>
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">INTERPRETE</strong>
                                                <span className="col text-right text-secondary">{song.interpreter ? song.interpreter.name : '-'}</span>
                                            </p>
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">% PARTICIPACION</strong>
                                                <span className="col text-right text-secondary">50%</span>
                                            </p>
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">EDITORA</strong>
                                                <span className="col text-right text-secondary">{song.editor && song.editor.name ? song.editor.name : '-'}</span>
                                            </p>
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">EDI ENCARGADO</strong>
                                                <span className="col text-right text-secondary">{song.editor && song.editor.encargado ? song.editor.encargado.name : '-'}</span>
                                            </p>
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">TEL ENCARGADO</strong>
                                                <span className="col text-right text-secondary">{song.editor && song.editor.encargado ? song.editor.encargado.phone : '-'}</span>
                                            </p>
                                            <p className="row w-100 justify-between my-1">
                                                <strong className="col sm-mov-85">FECHA DE GRABACION</strong>
                                                <span className="col text-right text-secondary">{extractDateR(song.end_time_requested)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItem>
                                <AccordionItem header={<AccordionHeader titulo={'Interprete'}/>}>
                                    <div className="row gap-1 justify-items-center">
                                        <div className="col-2">
                                            <img src={song.interpreter && song.interpreter.photo != null ? urlbase+song.interpreter.photo : urlbase+'/images/usuario.png'} className="img-profile-med"/>
                                        </div>
                                        <div className="col text-left">
                                            <p className="tag_inl is_link clear-my">{song.interpreter ? song.interpreter.name : '-'}</p>
                                            <p className="stx_tt text-secondary sm-text clear-my">{song.interpreter ? song.interpreter.phone : ''}</p>
                                        </div>
                                    </div>
                                </AccordionItem>
                                <AccordionItem header={<AccordionHeader titulo={'Contrato'}/>}>
                                    <div className='row w-100'>
                                        <div className="list-ficha w-100 px-3 text-left">
                                            <p className="row w-100 justify-between">
                                                <strong className="col sm-mov-85">INCIÓ</strong>
                                                <span className="col text-right text-secondary">{song.type_date_contract == 'Infinita' ? '∞': extractDateR(song.contract_date_start)}</span>
                                            </p>
                                            <p className="row w-100 justify-between py-1">
                                                <strong className="col sm-mov-85">FINALIZÁ</strong>
                                                <span className="col text-right text-secondary">{song.type_date_contract == 'Infinita' ? '∞': extractDateR(song.contract_date_end)}</span>
                                            </p>
                                        </div>
                                        <div className='row w-100'>
                                            {song.contract && song.contract != '' ? <a className="tag-phone tag-phone-mv w-100" href={urlbase+song.contract} target="_blank">
                                                <IoReaderSharp  className="icon-phone-tg mr-1"/>
                                                <span className="phone-number is_link sm-py-3">Descargar contrato</span>
                                            </a> : <span className="sm-text sm-py-3">No existe el contrato</span>}
                                        </div>
                                    </div>
                                </AccordionItem>
                                <AccordionItem header={<AccordionHeader titulo={'Pistas anteriores'}/>}>
                                    <div className="row my-1">
                                        <label className="lb-inp-usm mb-1 w-100">Pista inicial</label>
                                        {song.track ? <AudioPlayer 
                                            src={urlbase+song.track}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        /> : 'No cargada'}
                                    </div>
                                    <div className="row my-1">
                                        <label className="lb-inp-usm mb-1 w-100">Pista demo</label>
                                        {song.demo_track ? <AudioPlayer 
                                            src={urlbase+song.demo_track}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        /> : 'No cargada'}
                                    </div>
                                    <div className="row my-1">
                                        <label className="lb-inp-usm mb-1 w-100">Master grabacion</label>
                                        {song.master_recording ? <AudioPlayer 
                                            src={urlbase+song.master_recording}
                                            volumePlacement="top"
                                            className={"w-100 tracker_mp3_full"}
                                        /> : 'No cargada'}
                                    </div>
                                </AccordionItem>
                                <AccordionItem header={<AccordionEditHead icon={<IoSend className='icon-md icn_chevron mr-1' />} titulo={'Se envio á ('+sharings.length+")"} initialEntered={false}/>} >
                                    <div className="row w-100">
                                        {sharings.map((send, ind) => {
                                            if (send.song && send.song._id && send.client) {
                                                return(<div className='row w-100 justify-items-center py-1 bd-gain-1'>
                                                    <div className='col'>
                                                        <div className="row gap-1 justify-items-center">
                                                            <div className="col-1">
                                                                <img src={send.client && send.client.photo != null ? urlbase+send.client.photo : urlbase+'/images/usuario.png'} className="img-profile-sm mr-1" />
                                                            </div>
                                                            <div className="col text-left">
                                                                <p className="tag_inl is_link clear-my">{send.client ? send.client.name : '-'}</p>
                                                                <p className="text-secondary sm-text-75 clear-my">
                                                                    <div className="row w-100 justify-items-center">
                                                                        <IoSend className="icon-btn sm-text mr-m" /> 
                                                                        <span>{extractDateFormatR(send.created)}</span>
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        <p className="w-100 sm-text color-secondary w-100">{extractHour(send.created)}</p>
                                                    </div>
                                                </div>)
                                            }
                                        })}
                                    </div>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-100 py-3'></div>
        </div>
    )
}
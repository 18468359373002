import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {validPhone, separarImagen} from "../utility/utils"
import {IoChevronBack, IoCaretDownOutline } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import Compress from 'compress.js'


export default function AuthorForm({open, setOpen, isComplete, authorId, type, setMessage, idSucursal}) {
    const {setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [image, setImage] = useState("/images/usuario.png");
    const [image_f, setImagef] = useState(null);
    const [s_file, setSFile] = useState(null);
    const reader = new FileReader();
	const [author, setAuthor] = useState({
        name: "",
        phone: "",
        email: "",
        photo: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setAlert(null);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (author.name != ""){
            	let response = null;
                let formData = new FormData();
                formData.append('name', author.name);
                formData.append('phone', author.phone);
                formData.append('email', author.email);
                formData.append('photo', author.photo);
                if (idSucursal){
                    formData.append('branch', idSucursal);
                }
                setLoading(true);
                button.disabled = true;
	            if (authorId) {
	            	response = await fetch(urlbase+"/app/update_author/"+authorId, {
		                method: 'PUT',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_author", {
		                method: 'POST',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito"){
                    if (authorId) {
                        setMessage({text: type+" actualizado", type:"done"});
                    }else{
                        setMessage({text: type+" guardado", type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setAuthor({ name: "", phone: "", email: "", photo:""});
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert("Completa al menos el nombre");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadAuthor = async (id) => {
    	const res = await fetch(urlbase+"/app/get_author/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.author;
        setAuthor({ name: data.name, photo:data.photo, email: data.email});
        if (data.photo) {
            setImage(urlbase+data.photo);
        }
        setIsLoad(false);
    }
    const completeImage = (croppedImage) => {
        if (croppedImage) {
            setImagef(croppedImage);
        }
    }
    const handleChange = (e) => {
        setAuthor({...author, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleChangeFile = (e) => {
        let file = e.target.files[0];
        var compress = new Compress();
        var files = [file];
        compress.compress(files, {size: 2,quality: 0.85,maxWidth: 800,maxHeight: 800,resize: true})
        .then((images) => {
            var img = images[0];
            var imagen = img.prefix+img.data.toString();
            var file_t = separarImagen(imagen);
            if (file_t) {
                reader.onload = function (e) {
                    setImage(e.target.result);
                }
                reader.readAsDataURL(file_t);
                setSFile(true);
            }
        });
    }

    
    useEffect(() => {
        if (open) {
            setAuthor({ name: "", phone: "", email: "", photo:""});
            setAlert(null);
            if (authorId != null){
                loadAuthor(authorId);
            }else{
                setIsLoad(false);
                setImage("/images/usuario.png");
            }
        }
    }, [open]);

    useEffect(() => {
        if (s_file) {
            setAuthor({...author, ['photo']: image_f});
        }
    }, [image_f]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{authorId != null ? 'Editar '+type : 'Nuevo '+type}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    <div className="row gap-1 w-100">
                        <label className="lb-inp-usm">Nombre completo</label>
                        <input type="text" className="inp_add text-uppercase" value={author.name} onChange={handleChange} name="name" placeholder="Nombre *" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 my-1 w-100">
                        <label className="lb-inp-usm">Telefono (opcional)</label>
                        <input type="text" className="inp_add" value={author.phone} onChange={handleChange} name="phone" placeholder="Telefono" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 w-100">
                        <label className="lb-inp-usm">Correo (opcional)</label>
                        <input type="text" className="inp_add" value={author.email} onChange={handleChange} name="email" placeholder="Correo" autoComplete="off"/>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1 w-100">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{authorId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}
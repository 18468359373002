import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {useNavigate} from 'react-router-dom'
import {IoChevronBack, IoSettingsSharp, IoToday, IoCloudUploadOutline, IoCloseCircle, IoCheckmark, IoRefreshCircle } from "react-icons/io5";
import {extractDate, limitText} from "../utility/utils"
import ProgressModal from "../ui/ProgressModal";


export default function ApartarForm({open, setOpen, isComplete, cancionId, isAcc, isEdit, setMessage, idSucursal, idClient}) {
    const {session, loading, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [clients, setClients] = useState([]);
    const [isFailed, setFailed] = useState(true);
    const navigate = useNavigate();
	const [song, setSong] = useState({
        title: "",
        authors:"",
        track: "",
        demo_track: "",
        cover_file: "",
        perc_parti: "",
        folio_reg: "",
        requested: "",
        by_other: "",
        end_time_requested: "",
        observation_req: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setAlert(null);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (song.requested != ""){
                let formData = new FormData();
                if (isAcc == 'Apartar_cero') {
                    formData.append('title', song.title);
                    formData.append('authors', song.authors);
                    formData.append('track', song.track);
                    formData.append('folio_reg', song.folio_reg);
                    formData.append('perc_parti', song.perc_parti);
                    formData.append('demo_track', song.demo_track);
                }
                formData.append('requested', song.requested);
                formData.append('by_other', song.by_other);
                formData.append('end_time_requested', song.end_time_requested);
                formData.append('observation_req', song.observation_req);
                formData.append('accion', isAcc);
                if (idSucursal){
                    formData.append('branch', idSucursal);
                }
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                Req.open("PUT", urlbase+"/app/update_song_apartada/"+cancionId, true);
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        if(res.clave && res.clave == "exito") {
                            if (isAcc == 'Apartar_edit') {
                                setMessage({text: "Tema apartado actualizado!", type:"done"});
                                isComplete();
                            }else{
                                if (isAcc == 'Apartar_cero') {
                                    setMessage({text: "Tema agregado con exito!", type:"done"});
                                    isComplete();
                                }else{
                                    setMessage({text: "Tema apartado con exito!", type:"done"});
                                    setTimeout(function() {
                                        navigate("/app/catalogo/apartadas");
                                    }, 1000);
                                }
                            }
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            setSong({ title: "", authors:"", by_other: "", track: "", state: "Apartado", creation_date: new Date(), song_lyrics: "", cover_file: "", end_time_requested: "", observation_req:"",});
                            window.scrollTo(0,0);
                            setIsLoad(true);
                        }else{
                            setAlert(res.mensaje);
                            setLoading(false);
                            button.disabled = false;
                        }
                    }
                }
                if (isAcc == 'Apartar_cero' && song.demo_track == '') {
                    if (song.title != '') {
                        setAlert("Debes cargar la pista demo");
                        setLoading(false);
                        button.disabled = false;
                    }else{
                        setAlert("Debes agregar un titulo de creacion");
                        setLoading(false);
                        button.disabled = false;
                    }
                }else{
                    Req.send(formData);
                }
            }else{
            	setAlert("Debes elegir un artista");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_song/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.song;
        const clientsdb = response.clients;
        if (isAcc == 'Apartar_edit') {
            setSong({ title: data.title, by_other: data.by_other, requested:data.requested._id, end_time_requested:data.end_time_requested, observation_req:data.observation_req, coauthor:data.coauthor, folio_reg:data.folio_reg, state: data.state});
        }else{
            await setSong({ title: data.title, by_other: "", requested:idClient ? idClient : '', coauthor:data.coauthor, end_time_requested: "", observation_req:"", folio_reg:data.folio_reg, state: data.state});
        }
        if (data.cover_file) {
            setImage(urlbase+data.cover_file);
        }
        if (clientsdb && clientsdb.length > 0) {
            setClients(clientsdb);
        }
        setIsLoad(false);
    }
    const loadInitsSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_clients", {headers:myHeaders()});
    	const clientsdb = await res.json();
        if (clientsdb && clientsdb.length > 0) {
            setClients(clientsdb);
        }
        setIsLoad(false);
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleChange = (e) => {
        setSong({...song, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleSelectClient = async (e) => {
        setSong({...song, ['requested']: e.target.value});
        if (e.target.value != '') {
            const res = await fetch(urlbase+"/app/comprobar_send/"+cancionId+"/"+e.target.value, {headers:myHeaders()});
    	    const response = await res.json();
            console.log(response);
            
        }
        setAlert(null);
    }
    const handleMp3 = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['demo_track']: e.target.files[0]});
        }else{
            setSong({...song, ['demo_track']: null});
        }
    }
    const handleTrack = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['track']: e.target.files[0]});
        }else{
            setSong({...song, ['track']: null});
        }
    }
    

    useEffect(() => {
        if (open) {
            setClients([]);
            setSong({ title: "", authors:"", requested: "", by_other: "", track: "", demo_track: "", perc_parti: "", folio_reg: "", creation_date: new Date(), song_lyrics: "", cover_file: "", end_time_requested: "", observation_req:""});
            setAlert(null);
            if (cancionId != null){
                loadSong(cancionId);
            }else{
                loadInitsSong();
                setIsLoad(false);
                setImage("/images/caratula.jpg");
            }
        }
    }, [open]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{isAcc == 'Apartar' ? 'Apartar Tema' : isAcc == 'Apartar_edit' ? 'Editar tema apartado' : isAcc == 'Apartar_cero' ? 'Crear y apartar' : '-'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                {isAcc == 'Apartar_cero' ? <div className="row gap-1 w-100 mb-2">
                    <div className='row gap-1 w-100'>
                        <div className="col-1">
                            <IoSettingsSharp className="icon-md"/>
                        </div>
                        <div className="col text-left">
                            <strong className="tag_inl clear-my text-uppercase sm-text is_link">Datos de creación</strong>
                        </div>
                    </div>
                    <div className="row border-form p-2 w-100">
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Titulo</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="text" className="inp_add in-requi" value={song.title} onChange={handleChange} name="title" placeholder="Titulo original" autoComplete="off"/>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">Autor</label>
                            <input type="text" className="inp_add" value={song.authors} onChange={handleChange} name="authors" placeholder="Autor1,Autor2,Autor3" autoComplete="off"/>
                        </div>
                        <div className="row w-100 ">
                            <label className="lb-inp-usm mb-1">Pista de audio</label>
                            <input type="file" className="inp_add" onInput={handleTrack} name="track" accept=".mp3,audio/"/>
                        </div>
                        <div className="row gap-1 w-100 my-1">
                            <div className="col">
                                <label className="lb-inp-usm mb-1">Folio de registro</label>
                                <input type="text" className="inp_add text-uppercase" value={song.folio_reg} onChange={handleChange} name="folio_reg" placeholder="FL656556565655 (opcional)" autoComplete="off"/>
                            </div>
                            <div className="col-5">
                                <label className="lb-inp-usm mb-1">Participacion</label>
                                <input type="number" min="1" className="inp_add" value={song.perc_parti} onChange={handleChange} name="perc_parti" placeholder="50%" autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Pista demo</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="file" className="inp_add in-requi" onInput={handleMp3} name="demo_track" accept=".mp3,audio/"/>
                        </div>
                    </div>
                </div> : ''}
                <div className="row gap-1 w-100">
                    {isAcc == 'Apartar' || isAcc == 'Apartar_edit' ? <div className="row w-100">
                        <label className="lb-inp-usm mb-1">TEMA</label>
                        <a className="tag-phone f_imp row justify-between justify-items-center w-100">
                            <div className="col sm-col-12">
                                <img src={image != '/images/caratula.jpg' ? image : urlbase+'/images/caratula.jpg'} className="img-profile-sm sm-img-med mt-p3 mr-1" />
                                <p className="tag_inl inl_bk_sm clear-my">
                                    <div className='is_link'>{limitText(song.title, 20)}</div>
                                    <div className='sm-text-75 text-secondary'>{song.folio_reg}</div>
                                </p>
                            </div>
                        </a>
                    </div>: ''}
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1 w-100">
                            <div className='row w-100 justify-between'>
                                <span>Cliente que la aparto</span>
                                <span className='tg-requir'>obligatorio</span>
                            </div>
                        </label>
                        <div className='position-relative w-100'>
                            <select className="inp_add in-requi clear_sl_p" value={song.requested} name="requested" onInput={handleSelectClient} onfocus="$(this).hide().next().attr('hidden',false).focus();" hidden="true">
                                <option value="">Elegir...</option>
                                {
                                    clients.map((client, ind) => {
                                        return (<option value={client._id}>{client.name}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1">Apartado por: </label>
                        <input type="text" className="inp_add" value={song.by_other} onChange={handleChange} name="by_other" placeholder="Nombre de la persona" autoComplete="off"/>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1">Fecha limite de apartado</label>
                        <div className='position-relative w-100'>
                            <input type="date" className="inp_add inpd_m text-left" value={extractDate(song.end_time_requested)} onInput={handleChange} name="end_time_requested"/>
                            <IoToday className="icn_fl_sel icon-sm showMovil" />
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{loading ? 'SUBIENDO...': isAcc == 'Apartar_edit' ? 'Aplicar' : 'Apartar'}</button>
                    </div>
                </div>
            </div>
            { loading ? <div className='fixed-upload'>
                <div className='center-content'>
                    <div className="row w-100">
                        <div className='row w-100 text-center justify-center p-3'>
                            <IoCloudUploadOutline className="icon-upld-anim"/>
                        </div>
                        <div className='row w-100 p-2'></div>
                        <div className="row w-100 cont_downloaad">
                            <div className="img_inl text-center">
                                <img src={'/images/caratula.jpg'} className="img-profile-med" />
                            </div>
                            <div className="text_inl text-left">
                                <p className="tag_tt_song clear-my">SUBIENDO...</p>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">Pista de audio y Poster</p>
                            </div>
                            <div className="row w-100 text-center">
                                <div className='barr_gains showBarr w-100'>
                                    <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                </div>
                            </div>
                            {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                <IoCheckmark className="checkisdone"/>
                            </span> : ''}
                            {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                        </div>
                        {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                            <p className="text-message p-2">Error al subir los archivos, mala conexion</p>
                            <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                        </div> : ''}
                        <div className='row w-100 text-center p-3'>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">No cierres esta ventana hasta que se complete la subida de archivos</p>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
	)
}
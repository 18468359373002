import MovilMenu from "../components/ui/MovilMenu";
import TrackPlayer from "../components/ui/TrackPlayer";
import Header from "../components/ui/Header";
import SideMenu from "../components/ui/SideMenu";
import ProtectedAdmin from "../components/protected/ProtectedAdmin"
import {IoReceipt , IoApps, IoHome, IoPerson, IoMusicalNotesSharp, IoListSharp, IoPeopleSharp, IoPersonCircleSharp, IoPersonAddSharp, IoSend } from "react-icons/io5";
import {useApp} from "../context/appContext";
import {Outlet} from 'react-router-dom'

export default function Dashboard({children}) {
    const {playlist, setPlaylist} = useApp();
    let items = [
        {name:'Inicio', url:'/app/tablon', icon:(<IoHome className="icon_it_he"/>)},
        {name:'Enviadas', url:'/app/enviadas', icon:(<IoSend  className="icon_it_he"/>)},
        {name:'Creaciones', url:'/app/creaciones', icon:(<IoMusicalNotesSharp  className="icon_it_he"/>)},
        {name:'Catalogo', url:'/app/catalogo', icon:(<IoListSharp className="icon_it_he"/>)},
        {name:'Clientes', url:'/app/clientes', icon:(<IoPeopleSharp className="icon_it_he"/>)},
        {name:'Artistas', url:'/app/artistas', icon:(<IoPersonCircleSharp  className="icon_it_he"/>)},
        {name:'Autores', url:'/app/autores', icon:(<IoPersonAddSharp className="icon_it_he"/>)},
        {name:'Editoras', type:'app', url:'/app/editoras', icon:(<IoReceipt className="icon_it_he"/>)},
        {name:'Usuarios', url:'/app/usuarios', icon:(<IoPerson  className="icon_it_he"/>)},
    ];
    let itemsM = [
        {name:'Inicio', url:'/app/tablon', icon:(<IoHome className="icon_it_mv"/>)},
        {name:'Creaciones', url:'/app/creaciones', icon:(<IoMusicalNotesSharp  className="icon_it_mv"/>)},
        {name:'Catalogo', url:'/app/catalogo', icon:(<IoListSharp className="icon_it_mv"/>)},
        {name:'Clientes', url:'/app/clientes', icon:(<IoPeopleSharp className="icon_it_mv"/>)},
        {name:'Mas', url:'/app/more_options', icon:(<IoApps className="icon_it_mv"/>)}
    ];
    

    return(
        <>
            <ProtectedAdmin>
                <Header></Header>
                <div className="d-flex">
                    <SideMenu items={items}>
                        <div className="py-2"></div>
                    </SideMenu>
                    <main className="container_main col-md-12 col-lg-10 ms-sm-auto px-md-4 fadeIn">
                        <div className="p-2 md-p-2">
                            <Outlet />
                        </div>
                        {playlist && playlist.length > 0 ? <TrackPlayer playlist={playlist} setPlaylist={setPlaylist}/> : ''}
                    </main>
                </div>
                <MovilMenu items={itemsM}></MovilMenu>
            </ProtectedAdmin>
        </>
    )
}



        
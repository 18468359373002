import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

export default function Modal({open, setOpen, size, height, children, handleClosed}){
    const stopEvent = (e) => {
        e.stopPropagation();
    }
    return(
        <div className={open ? 'cont_modal' : 'cont_modal dnone'} id="modal_opaci" onClick={handleClosed}>
            <div className={'main_modal slideInUp ' + size + " "+ height } id="main_modal" onClick={stopEvent}>
                <IoClose className="cerrar_modal" onClick={handleClosed}/>
                {children}
            </div>
        </div>
    )
}
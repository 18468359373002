import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

export default function OptionMovil({open, setOpen, children}){
    const stopEvent = (e) => {
        e.stopPropagation();
    }
    const handleClosed = (e) => {
        e.stopPropagation();
        setOpen(false);
    }

    return(
        <div className={open ? 'cont_option' : 'cont_option dnone'} onClick={handleClosed}>
            <div className="main_option rounded-4 slideInUp">
                {children}
            </div>
        </div>
    )
}